<template>
	<div class="payments_form-container">
		<img :src="logoBlack" />
		<h4>상품을 신청하기 위하여 필요한 정보를 넣어주세요.</h4>
		<h2>구매 항목</h2>
		<div class="form_group form_goods">
			<div class="form_title">※상품</div>
			<div class="form_content">모듈러스 프로페셔널</div>
		</div>
		<hr />
		<div class="form_group form_addfunc">
			<div class="form_title">※상세추가기능</div>
			<div class="form_content">
				<ul>
					<li>실시간 협업 기능 ※최대 10개프로젝트, 최대 20명</li>
					<li>소스코드 저장 및 공유</li>
					<li>팀원 간 파일 공유</li>
					<li>ECHC를 적용하여 안전한 데이터 전송</li>
				</ul>
			</div>
		</div>
		<hr />
		<div class="form_group form_radio">
			<div class="form_title">
				※이용기간
				<p>이용기간을 꼭 체크해주세요</p>
			</div>
			<div class="form_content">
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="period"
							v-model="form.periodUse"
							value="1개월"
							id="month1"
						/>
						<label class="radio_check-btn" for="month1">✔</label>
					</div>
					<label>1개월 결제</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="period"
							v-model="form.periodUse"
							value="2개월"
							id="month2"
						/>
						<label class="radio_check-btn" for="month2">✔</label>
					</div>
					<label>2개월 결제</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="period"
							v-model="form.periodUse"
							value="3개월"
							id="month3"
						/>
						<label class="radio_check-btn" for="month3">✔</label>
					</div>
					<label>3개월 결제</label>
				</div>
			</div>
		</div>
		<hr />
		<div class="form_group form_due_date">
			<div class="form_title">
				※만기일
				<p>구매일에 따라 만기일이 달라질수 있습니다.</p>
			</div>
			<div class="form_content">
				<label
					>시작일 {{ form.startDateObj.year }}년 {{ form.startDateObj.month }}월
					{{ form.startDateObj.day }}일
				</label>
				<label
					>만기일 {{ form.endDateObj.year }}년 {{ form.endDateObj.month }}월
					{{ form.endDateObj.day }}일
				</label>
			</div>
		</div>
		<hr />
		<div class="form_group form_coupon">
			<div class="form_title">쿠폰적용</div>
			<div class="form_content">
				<select v-model="selectedCoupon">
					<option value="">쿠폰을 선택해 주세요</option>
					<option
						v-for="coupon in couponList"
						:key="coupon.id"
						:value="coupon.id"
					>
						{{ coupon.cpnNmbr }}
					</option>
				</select>
			</div>
		</div>
		<hr />
		<div class="form_group form_pay_detail">
			<div class="form_title">※결제상세</div>
			<div class="form_content">
				<div>
					<label>이용기간</label>
					<input type="text" :value="form.periodUse" disabled />
				</div>
				<div>
					<label>금액</label>
					<input type="text" :value="form.totalDscntAmount" disabled />
				</div>
			</div>
		</div>
		<div class="form_group form_order_info">
			<fieldset>
				<header>
					<h3>※구매자 정보</h3>
					<span>※ 필수 입니다.</span>
				</header>
				<div class="form_order_info-box">
					<input
						v-model="form.ordererName"
						type="text"
						placeholder="※ 구매자성함"
					/>
					<input
						v-model="form.ordererPhoneNum"
						type="text"
						placeholder="※ 전화번호"
					/>
					<input
						v-model="form.ordererEmail"
						type="text"
						placeholder="※ 이메일"
					/>
				</div>
			</fieldset>
		</div>
		<div class="form_group form_radio form_pay_method">
			<h3>※결제 수단</h3>
			<div class="form_content">
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="card"
							v-model="form.methodType"
							value="card"
						/>
						<label for="card" class="radio_check-btn">✔</label>
					</div>
					<label>신용카드</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="bank"
							v-model="form.methodType"
							value="bank"
						/>
						<label for="bank" class="radio_check-btn">✔</label>
					</div>
					<label>계좌이체</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="vbank"
							v-model="form.methodType"
							value="vbank"
						/>
						<label for="vbank" class="radio_check-btn">✔</label>
					</div>
					<label>가상계좌</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="naverpayCard"
							v-model="form.methodType"
							value="naverpayCard"
						/>
						<label for="naverpayCard" class="radio_check-btn">✔</label>
					</div>
					<label>네이버페이</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="kakaopay"
							v-model="form.methodType"
							value="kakaopay"
						/>
						<label for="kakaopay" class="radio_check-btn">✔</label>
					</div>
					<label>카카오페이</label>
				</div>
				<div class="radio-box">
					<div class="radio-btn">
						<input
							type="radio"
							name="methodType"
							id="samsungpayCard"
							v-model="form.methodType"
							value="samsungpayCard"
						/>
						<label for="samsungpayCard" class="radio_check-btn">✔</label>
					</div>
					<label>삼성페이</label>
				</div>
			</div>
		</div>
		<div class="form-submit">
			<input
				type="button"
				value="결제하기"
				@click="serverAuth()"
				:class="{ submitDisabled: !allCheck }"
				:disabled="!allCheck || isPaymentInProgress"
			/>
		</div>
	</div>
</template>

<script>
import store from '@/store/index.js';
import logoBlack from '@/assets/images/fctslogoblack.svg';
import axios from 'axios';
import fctsbuyer from '../../services/couponList';
import paymentsService from '../../services/paymentsService';
// import couponList from '../../services/couponList';
export default {
	name: 'FctsMinjeongPaymentsForm',

	data() {
		return {
			form: {
				userId: store.state.userid,
				prdctName: 'mod_pro',
				periodUse: '1개월',
				amount: 78000,
				dscnt: 0,
				totalDscnt: 0,
				totalAmount: 0,
				ordererName: '',
				ordererPhoneNum: '',
				ordererEmail: '',
				methodType: '',
				startDateObj: {},
				endDateObj: {},
				startDate: '',
				endDate: '',
				totalDscntAmount: 0,
			},
			logoBlack,
			today: {},
			oneMonthLater: {},
			twoMonthLater: {},
			threeMonthLater: {},
			couponList: [], // 쿠폰 목록을 저장할 배열
			selectedCoupon: '', // 선택된 쿠폰의 ID를 저장할 변수
			isPaymentInProgress: false, // 결제 진행 중임을 표시하는 변수
		};
	},
	computed: {
		periodUseState() {
			if (this.form.periodUse !== '') {
				return true;
			} else {
				return null;
			}
		},
		totalAmountState() {
			if (this.form.totalAmount !== 0) {
				return true;
			} else {
				return null;
			}
		},
		ordererNameCheck() {
			if (this.form.ordererName !== '') {
				return true;
			} else {
				return null;
			}
		},
		ordererPhoneNumState() {
			if (this.form.ordererPhoneNum !== '') {
				return true;
			} else {
				return null;
			}
		},
		ordererEmailState() {
			if (this.form.ordererEmail !== '') {
				return true;
			} else {
				return null;
			}
		},
		methodTypeState() {
			if (this.form.methodType !== '') {
				return true;
			} else {
				return null;
			}
		},
		allCheck() {
			if (
				this.periodUseState == true &&
				this.totalAmountState == true &&
				this.ordererNameCheck == true &&
				this.ordererPhoneNumState == true &&
				this.ordererEmailState == true &&
				this.methodTypeState == true
			) {
				return true;
			} else {
				return false;
			}
		},
	},
	watch: {
		'form.periodUse'(newValue) {
			if (newValue === '1개월') {
				this.form.totalAmount = this.form.amount * 1;
				this.form.endDateObj = this.oneMonthLater;
			} else if (newValue === '2개월') {
				this.form.totalAmount = this.form.amount * 2;
				this.form.endDateObj = this.twoMonthLater;
			} else if (newValue === '3개월') {
				this.form.totalAmount = this.form.amount * 3;
				this.form.endDateObj = this.threeMonthLater;
			}
			this.periodUseDscntState();
			this.initialDateFormat();
		},
		selectedCoupon: function (value) {
			for (let i = 0; i < this.couponList.length; i++) {
				if (this.couponList[i].id === value) {
					this.form.dscnt = this.couponList[i].dscnt;
				}
			}
			if (value === '') {
				this.form.dscnt = 0;
			}
			this.periodUseDscntState();

			// 선택된 쿠폰이 비어 있지 않은 경우, 데이터베이스에서 쿠폰 삭제를 호출합니다.
			if (value !== '') {
				this.deleteSelectedCoupon(value);
			}
		},
	},
	mounted() {
		this.getCouponList(); // 컴포넌트가 마운트될 때 쿠폰 목록을 가져오는 함수 호출
		const date = new Date();
		this.setToday(date);
		this.setOneMonthLater(date);
		this.setTwoMonthLater(date);
		this.setThreeMonthLater(date);
		this.form.totalDscntAmount = this.form.totalAmount;
		this.form.totalDscnt = this.form.dscnt * 1;
	},

	methods: {
		setToday(date) {
			this.today = {
				year: date.getFullYear(),
				month: date.getMonth() + 1,
				day: date.getDate(),
			};
			this.form.startDateObj = this.today;
			this.initialDateFormat();
		},

		setOneMonthLater(date) {
			if (date.getMonth() + 2 > 12) {
				const year = date.getFullYear() + 1;
				const month = date.getMonth() + 2 - 12;
				let day = date.getDate();
				let lastDate = new Date(year, month, 0).getDate();
				if (day > lastDate) {
					day = lastDate;
				}
				this.oneMonthLater = {
					year: year,
					month: month,
					day: day,
				};
			} else {
				const year = date.getFullYear();
				const month = date.getMonth() + 2;
				let day = date.getDate();
				let lastDate = new Date(year, month, 0).getDate();
				if (day > lastDate) {
					day = lastDate;
				}
				this.oneMonthLater = {
					year: year,
					month: month,
					day: day,
				};
			}
			this.form.endDateObj = this.oneMonthLater;
			this.initialDateFormat();

			this.form.totalAmount = this.form.amount * 1;
		},

		setTwoMonthLater(date) {
			if (date.getMonth() + 3 > 12) {
				const year = date.getFullYear() + 1;
				const month = date.getMonth() + 3 - 12;
				let day = date.getDate();
				let lastDate = new Date(year, month, 0).getDate();
				if (day > lastDate) {
					day = lastDate;
				}
				this.twoMonthLater = {
					year: year,
					month: month,
					day: day,
				};
			} else {
				const year = date.getFullYear();
				const month = date.getMonth() + 3;
				let day = date.getDate();
				let lastDate = new Date(year, month, 0).getDate();
				if (day > lastDate) {
					day = lastDate;
				}
				this.twoMonthLater = {
					year: year,
					month: month,
					day: day,
				};
			}
		},

		setThreeMonthLater(date) {
			const year = date.getFullYear() + 1;
			const month = date.getMonth() + 4 - 12;
			let day = date.getDate();
			let lastDate = new Date(year, month, 0).getDate();
			if (day > lastDate) {
				day = lastDate;
			}
			if (date.getMonth() + 4 > 12) {
				this.threeMonthLater = {
					year: year,
					month: month,
					day: day,
				};
			} else {
				const year = date.getFullYear();
				const month = date.getMonth() + 4;
				let day = date.getDate();
				let lastDate = new Date(year, month, 0).getDate();
				if (day > lastDate) {
					day = lastDate;
				}
				this.threeMonthLater = {
					year: year,
					month: month,
					day: day,
				};
			}
		},

		async serverAuth() {
			this.isPaymentInProgress = true; // 결제가 진행 중임을 표시

			const formData = {
				userId: this.form.userId,
				prdctName: this.form.prdctName,
				periodUse: this.form.periodUse,
				ordererName: this.form.ordererName,
				ordererPhoneNum: this.form.ordererPhoneNum,
				ordererEmail: this.form.ordererEmail,
				totalAmount: this.form.totalAmount,
				methodType: this.form.methodType,
				startDate: this.form.startDate,
				endDate: this.form.endDate,
				totalDscntAmount: this.form.totalDscntAmount,
				vbankHolder: '포체인스 주식회사',
			};
			try {
				const response = await fctsbuyer.getbuy(formData);

				if (response.data.message === 'SUCCESS') {
					console.log(response.data);
					alert('구매 화면으로 넘어갑니다.');
				}
			} catch (error) {
				console.log(error);
				alert('구매내역을 다시 확인해 주세요.');
			}

			try {
				const res = await paymentsService.getPaymentsKey();
				if (typeof window !== 'undefined') {
					const pay_obj = window;
					const { AUTHNICE } = pay_obj;

					// paymentWindow 변수를 만들고 AUTHNICE.requestPay의 결과를 할당합니다.
					AUTHNICE.requestPay({
						clientId: res.data.clientId,
						method: formData.methodType,
						orderId: res.data.orderId,
						amount: formData.totalAmount,
						goodsName: formData.prdctName,
						vbankHolder: formData.ordererName,
						returnUrl: `${process.env.VUE_APP_API_URL}/api/payments/serverAuth`,
						fnError: async function (result) {
							console.log(result.errorMsg);
							try {
								// 데이터베이스에서 userId를 사용하여 데이터 삭제
								await paymentsService.deleteCancelPayments(formData.userId);
								console.log(
									'결제가 취소되고 데이터베이스 정보가 삭제되었습니다.',
								);
							} catch (error) {
								console.error('결제 취소 실패:', error);
							}
						},
					});
					// // 결제 창이 닫힐 때의 이벤트 처리
					// window.addEventListener('beforeunload', async event => {
					// 	event.preventDefault(); // 브라우저가 즉시 닫히는 것을 방지하기 위해 필요합니다.
					// 	event.returnValue =
					// 		'이 페이지를 벗어나면 결제가 취소될 수 있습니다.';

					// 	try {
					// 		// 데이터베이스에서 userId를 사용하여 데이터 삭제
					// 		await axios.delete(`/api/cancelPayment/${formData.userId}`);
					// 		console.log('결제 정보가 삭제되었습니다.');
					// 	} catch (error) {
					// 		console.error('결제 정보 삭제 실패:', error);
					// 	}
					// });
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.isPaymentInProgress = false; // 결제 완료 후 플래그 초기화
			}
		},
		async getCouponList() {
			try {
				const formData = {
					userId: this.form.userId, // 유저의 아이디를 formData에 추가
				};

				const response = await axios.post('/api/get/coupons', formData);
				this.couponList = response.data;
				// dscnt 변수에 할인 금액 전달

				// console.log();
				console.log(response.data);
			} catch (error) {
				console.error('쿠폰 목록 조회 실패:', error);
			}
		},
		async deleteSelectedCoupon(couponId) {
			try {
				// Axios를 사용하여 쿠폰을 삭제하는 API 호출
				await axios.delete(`/api/delete/coupon/${couponId}`);
				console.log(`쿠폰 ID ${couponId}가 데이터베이스에서 삭제되었습니다.`);
			} catch (error) {
				console.error('쿠폰 삭제 실패:', error);
			}
		},
		// 사용기간 별 할인금액 변경
		periodUseDscntState() {
			if (this.form.periodUse === '1개월') {
				this.form.totalDscnt = this.form.dscnt * 1;
			} else if (this.form.periodUse === '2개월') {
				this.form.totalDscnt = this.form.dscnt * 2;
			} else if (this.form.periodUse === '3개월') {
				this.form.totalDscnt = this.form.dscnt * 3;
			}
			this.form.totalDscntAmount = this.form.totalAmount - this.form.totalDscnt;
		},
		// 최초 시작일, 만기일 db Date 형식으로 포맷
		initialDateFormat() {
			this.form.startDate = `${this.form.startDateObj.year}-${this.form.startDateObj.month}-${this.form.startDateObj.day}`;
			this.form.endDate = `${this.form.endDateObj.year}-${this.form.endDateObj.month}-${this.form.endDateObj.day}`;
		},
	},
};
</script>

<style></style>
